import axios from 'axios'

import {dispatch, getState} from "./index";
import {CONFIG} from "../../AppConfig";
import {isFunction} from "../../utils/misc";
import print from "../../utils/print";
import {toggle} from "./context";
import {DASHBOARD_CLEAR, DASHBOARD_SET} from "../types";
import {getHTTPConfig} from "../../utils/requests";

const URL = CONFIG.URL_API

export const getDashboardInfo = (onSuccess, onFail) => {
    toggle('dashboardIsGettingDashboardInfo', true)
    toggle('dashboardErrorToGetDashboardInfo', false)

    const {studentID, campusID} = getState().AUTH

    const url = `${URL}enrollment/getDashboardInfo/${studentID}/${campusID}`

    axios.get(url, getHTTPConfig()).then(result => {
        print('GET DASHBOARD INFO', result)

        const data = result.data
        const payload = {
            cqpa: data.cqpa,
            cqpaPerProgClass: data.cqpaPerProgClass,
            programCompletionPercent: data.programCompletionPercent,
            latestRegAyTerm: data.latestRegAyTerm,
            latestRegID: data.latestRegID,
            latestRegStatus: data.latestRegStatus,
            currUnitsEarned: data.currUnitsEarned,
            currUnitsTotal: data.currUnitsTotal,
            currNumCourses: data.currNumCourses,
            currCoursesCreditUnits: data.currCoursesCreditUnits,
            currCoursesLecUnits: data.currCoursesLecUnits,
            currCoursesLabUnits: data.currCoursesLabUnits,
            currCreditedCourses: data.currCreditedCourses,
            currCreditedUnits: data.currCreditedUnits,
            currLecUnitsEarned: data.currLecUnitsEarned,
            currLabUnitsEarned: data.currLabUnitsEarned,
            currCreditCourseToEarn: data.currCreditCourseToEarn,
            currCreditUnitsToEarn: data.currCreditUnitsToEarn,
            currCreditLecUnitsToEarn: data.currCreditLecUnitsToEarn,
            currCreditLabUnitsToEarn: data.currCreditLabUnitsToEarn,
        }
        dispatch(DASHBOARD_SET, payload)
        toggle('dashboardIsGettingDashboardInfo', false)
        if (isFunction(onSuccess)) onSuccess()

    }).catch(error => {
        print('GET DASHBOARD INFO', error)
        toggle('dashboardErrorToGetDashboardInfo', true)
        toggle('dashboardIsGettingDashboardInfo', false)
        if (isFunction(onFail)) onFail()
    })

}

export const clearDashboardInfo = () => {
    dispatch(DASHBOARD_CLEAR)
}