import React from "react";
import ReactHelmet from "../../../components/core/Helmet";
import { useSelector } from "react-redux";
import GridContainer from "../../../components/core/containers/grid/GridContainer";
import Grid from "@material-ui/core/Grid";
import Space from "../../../components/core/Space";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import { getDashboardInfo } from "../../../redux/actions/dashboard";
import LoadingComponents from "../../../components/core/loader/LoadingComponents";
import ErrorLoad from "../../../components/custom/ErrorLoad";
import GraduateDashboard from "../../../components/custom/dashboard/GraduateDashboard";
import StudentDashboard from "../../../components/custom/dashboard/StudentDashboard";
import DashboardGeneralInfo from "./DashboardGeneralInfo";
import DashboardStatistics from "./DashboardStatistics";

import Card from "../../../components/custom/card/Card";
import FlexContainer from "../../../components/core/containers/flex/FlexContainer";
import Typography from "../../../components/core/typography/Typography";

export default function Dashboard() {
  const isDownSM = useBreakpointDown("sm");
  const {
    dashboardIsGettingDashboardInfo,
    dashboardErrorToGetDashboardInfo,
    programCompletionPercent,
  } = useSelector((state) => ({
    dashboardIsGettingDashboardInfo:
      state.CONTEXT.toggles.dashboardIsGettingDashboardInfo,
    dashboardErrorToGetDashboardInfo:
      state.CONTEXT.toggles.dashboardErrorToGetDashboardInfo,
    programCompletionPercent: state.DASHBOARD.programCompletionPercent,
  }));

  return (
    <>
      <ReactHelmet title={"Dashboard"} />
      {/*{canRegister && (*/}
      {/*  <div>*/}
      {/*    <NoticeRegisterNow />*/}
      {/*    <Space vertical space={isDownSM ? 2 : 4} />*/}
      {/*  </div>*/}
      {/*)}*/}
      {dashboardIsGettingDashboardInfo ? (
        <div style={{ marginBottom: 12 }}>
          <LoadingComponents mini />
        </div>
      ) : dashboardErrorToGetDashboardInfo ? (
        <ErrorLoad reload={getDashboardInfo} />
      ) : programCompletionPercent === 100 ? (
        <div>
          <GraduateDashboard />
        </div>
      ) : (
        <div>
          <StudentDashboard />
        </div>
      )}
      <Space vertical space={4} />
      <GridContainer spacing={isDownSM ? 4 : 3}>
        <Grid item xs={12} md={4} style={{ order: isDownSM ? 2 : 1 }}>
          <DashboardGeneralInfo />
        </Grid>
        <Grid item xs={12} md={8} style={{ order: isDownSM ? 1 : 2 }}>
          <DashboardStatistics />
        </Grid>
      </GridContainer>
      <Space vertical space={isDownSM ? 5 : 10} />
      <GridContainer spacing={isDownSM ? 4 : 3}>
        <Grid >
          <Card padding={4} height={"100%"}>
              <FlexContainer
                direction={"column"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <Typography variant={"h6"} color={"warning"} weight={"light"}>
                    Disclaimer: 
                </Typography>
                <Typography align={"center"}>
                    The GPA and Program Completion Percentage  displayed on this page are considered unofficial. 
                    The final and official computation is determined by the Registrar's Office. 
                    Please refer to the Registrar's Office for inquiries and related concerns.
                </Typography>
              </FlexContainer>
            </Card>
          </Grid>
      </GridContainer>
    </>
  );
}
