import React from "react";
import Space from "../../core/Space";
import Typography from "../../core/typography/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "../card/Card";
import FlexContainer from "../../core/containers/flex/FlexContainer";
import { gradeSerializer } from "../../../utils/grades";
import Button from "../../core/button/Button";
import { routes } from "../../../routes";
import { getStatus } from "../../../pages/menu/enrollment/Enrollment";
import GridContainer from "../../core/containers/grid/GridContainer";
import { useBreakpointDown } from "../../../utils/hooks/mediaQuery";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

export default function StudentDashboard() {
  const history = useHistory();
  const isDownSM = useBreakpointDown("sm");

  const {
    cqpa,
    cqpaPerProgClass,
    programCompletionPercent,
    latestRegID,
    latestRegStatus,
    latestRegAyTerm,
  } = useSelector((state) => ({
    cqpa: state.DASHBOARD.cqpa,
    cqpaPerProgClass: state.DASHBOARD.cqpaPerProgClass,
    programCompletionPercent: state.DASHBOARD.programCompletionPercent,
    latestRegID: state.DASHBOARD.latestRegID,
    latestRegStatus: state.DASHBOARD.latestRegStatus,
    latestRegAyTerm: state.DASHBOARD.latestRegAyTerm,
  }));

  return (
    <div>
      <GridContainer spacing={isDownSM ? 2 : 4}>
        <Grid item xs={12} md={4}>
          <Card padding={4} height={"100%"}>
            <FlexContainer
              direction={"column"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Typography variant={"h2"} color={"warning"} weight={"light"}>
                {/* {gradeSerializer(cqpa)} */}
                {gradeSerializer(cqpaPerProgClass)}
              </Typography>
              <Space vertical space={2} />
              <Typography align={"center"}>
                Cumulative Quality <br /> Point Average
              </Typography>
              <Space vertical space={3} />
              <Button fullwidth onClick={() => history.push(routes.grades)}>
                See Grades
              </Button>
            </FlexContainer>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card padding={4} height={"100%"}>
            <FlexContainer
              direction={"column"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Typography variant={"h2"} weight={"light"}>
                {programCompletionPercent}%
              </Typography>
              <Space vertical space={2} />
              <Typography align={"center"}>
                Program Completion <br /> Percentage
              </Typography>
              <Space vertical space={3} />
              <Button
                fullwidth
                onClick={() => history.push(routes.programEvaluation)}
              >
                See Program Evaluation
              </Button>
            </FlexContainer>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card padding={4} height={"100%"}>
            <FlexContainer
              direction={"column"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Typography variant={"h6"} weight={"light"} align={"center"}>
                {latestRegAyTerm}
              </Typography>
              <Space vertical space={2.5} />
              <Typography
                variant={"h3"}
                weight={"normal"}
                align={"center"}
                color={getStatus(latestRegStatus)}
              >
                {latestRegStatus && latestRegStatus.toUpperCase()}
              </Typography>
              <Space vertical space={3} />
              <Typography align={"center"}>
                Latest Registration Status
              </Typography>
              <Space vertical space={3.5} />
              <Button
                variant={""}
                fullwidth
                onClick={() => {
                  if (latestRegID !== "-") {
                    history.push(routes.enrollment);
                  }
                }}
              >
                See Registrations
              </Button>
            </FlexContainer>
          </Card>
        </Grid>
      </GridContainer>
    </div>
  );
}
